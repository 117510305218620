import React, { useContext } from "react";
import {
    FaMobileAlt,
    FaEnvelopeOpen,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaWhatsapp,
    FaYoutube,
    FaEnvelope,
    FaKey,
} from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { FloatingModeContext } from "@components/contexts/FloatingModeContext";
import { Link, navigate } from "gatsby";
import "@styles/PreHeader.css";
import useAuth from "@components/hooks/useAuth";
import { BiFace } from "react-icons/bi";
import { MdExitToApp } from "react-icons/md";
import { toast } from "react-toastify";

const PreHeader = ({ location }) => {
    const { floatingMode } = useContext(FloatingModeContext);
    const { isAuthenticated, state, loading, logout } = useAuth();

    return (
        <div
            className={`preheader background font-montserrat ${
                floatingMode ? "h-0 overflow-hidden" : ""
            }`}
        >
            {/** Barra dispositivos >=MD */}
            <div className="container hidden md:block">
                <div className="py-3 items-center justify-between flex">
                    {/** Contacto rápido */}
                    <div className="flex items-center space-x-5">
                        <a
                            target="_blank"
                            aria-label="WhatsApp"
                            rel="noopener noreferrer"
                            href="https://wa.me/34644507478"
                            className={`icon-link flex items-center space-x-3`}
                        >
                            <FaMobileAlt
                                className="inline-block w-5 h-5"
                                style={{ verticalAlign: "middle" }}
                            />
                            <span className="hidden text-xs lg:-ml-3 lg:inline-block lg:mr-5">
                                +34 644 507 478
                            </span>
                        </a>

                        <a
                            target="_blank"
                            aria-label="E-mail"
                            rel="noopener noreferrer"
                            href="mailto:info@iopos.academy"
                            className={`icon-link flex items-center space-x-3`}
                        >
                            <FaEnvelopeOpen
                                className="inline-block w-5 h-5"
                                style={{ verticalAlign: "middle" }}
                            />
                            <span className="hidden text-xs lg:-ml-3 lg:inline-block lg:mr-5">
                                info@iopos.academy
                            </span>
                        </a>
                    </div>

                    {/** Social */}
                    <div className="flex items-center space-x-5">
                        <a
                            target="_blank"
                            aria-label="Facebook"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/iopos.es/"
                            className={`icon-link`}
                        >
                            <FaFacebook
                                className="inline-block h-5 w-5"
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                        <a
                            target="_blank"
                            aria-label="Instagram"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/iopos.es/"
                            className={`icon-link`}
                        >
                            <FaInstagram
                                className="inline-block h-5 w-5"
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                        <a
                            target="_blank"
                            aria-label="LinkedIn"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/company/iopos"
                            className={`icon-link`}
                        >
                            <FaLinkedin
                                className="inline-block h-5 w-5"
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                        <a
                            target="_blank"
                            aria-label="WhatsApp"
                            rel="noopener noreferrer"
                            href="https://wa.me/34644507478"
                            className={`icon-link`}
                        >
                            <FaWhatsapp
                                className="inline-block h-5 w-5"
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                        <a
                            target="_blank"
                            aria-label="WhatsApp"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UCbjYROOdRtc_y1VEUeCiFBQ"
                            className={`icon-link`}
                        >
                            <FaYoutube
                                className="inline-block h-5 w-5"
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                        <Link to="/contacto" className={`icon-link`} title="Contacto">
                            <FaEnvelope
                                className="inline-block h-4 w-4"
                                style={{ verticalAlign: "middle" }}
                            />
                        </Link>

                        {process.env.GATSBY_AUTH_ENABLED === "true" &&
                            (loading ? (
                                <span>
                                    <AiOutlineLoading className="text-center w-4 h-4 animate-spin duration-300" />
                                </span>
                            ) : !isAuthenticated ? (
                                <button
                                    onClick={() => {
                                        navigate(
                                            `/login${
                                                location?.search
                                                    ? "?redirect=" + location.search
                                                    : ""
                                            }`,
                                        );
                                    }}
                                    className="icon-link"
                                    title="Iniciar sesión"
                                >
                                    <FaKey
                                        className="w-4 h-4"
                                        style={{
                                            verticalAlign: "middle",
                                        }}
                                    />
                                </button>
                            ) : (
                                <>
                                    <Link
                                        to="/private"
                                        title={`Autenticado como ${state?.user?.username}`}
                                        className="flex items-center justify-items-center duration-300 icon-link"
                                        activeClassName="duration-300 animate-pulse"
                                    >
                                        <BiFace className="w-5 h-5" />
                                    </Link>
                                    <button
                                        onClick={() => {
                                            window.confirm(
                                                "Se va a cerrar la sesión ¿Estás seguro?",
                                            ) &&
                                                logout() &&
                                                toast.info("Sesión cerrada exitosamente.", {
                                                    position: "bottom-center",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    autoClose: 5000,
                                                });
                                        }}
                                        title={`Cerrar sesión`}
                                        className="flex items-center justify-items-center duration-300 icon-link"
                                    >
                                        <MdExitToApp className="w-5 h-5 text-cpurple" />
                                    </button>
                                </>
                            ))}
                    </div>
                </div>
            </div>

            {/* Dispositivos < MD */}
            <div className="container md:hidden">
                <div className="flex items-center justify-between py-3">
                    <a
                        target="_blank"
                        aria-label="WhatsApp"
                        rel="noopener noreferrer"
                        href="https://wa.me/34644507478"
                        className={`icon-link`}
                    >
                        <FaMobileAlt
                            className="inline-block w-5 h-5"
                            style={{ verticalAlign: "middle" }}
                        />
                        <span className="hidden text-xs lg:-ml-3 lg:inline-block lg:mr-5">
                            +34 644 507 478
                        </span>
                    </a>

                    <a
                        target="_blank"
                        aria-label="E-mail"
                        rel="noopener noreferrer"
                        href="mailto:info@iopos.academy"
                        className={`icon-link`}
                    >
                        <FaEnvelopeOpen
                            className="inline-block w-5 h-5"
                            style={{ verticalAlign: "middle" }}
                        />
                        <span className="hidden text-xs lg:-ml-3 lg:inline-block lg:mr-5">
                            info@iopos.academy
                        </span>
                    </a>

                    <a
                        target="_blank"
                        aria-label="Facebook"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/iopos.es/"
                        className={`icon-link`}
                    >
                        <FaFacebook
                            className="inline-block h-5 w-5"
                            style={{ verticalAlign: "middle" }}
                        />
                    </a>

                    <a
                        target="_blank"
                        aria-label="Instagram"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/iopos.es/"
                        className={`icon-link`}
                    >
                        <FaInstagram
                            className="inline-block h-5 w-5"
                            style={{ verticalAlign: "middle" }}
                        />
                    </a>

                    <a
                        target="_blank"
                        aria-label="LinkedIn"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/iopos"
                        className={`icon-link`}
                    >
                        <FaLinkedin
                            className="inline-block h-5 w-5"
                            style={{ verticalAlign: "middle" }}
                        />
                    </a>

                    <a
                        target="_blank"
                        aria-label="WhatsApp"
                        rel="noopener noreferrer"
                        href="https://wa.me/34644507478"
                        className={`icon-link`}
                    >
                        <FaWhatsapp
                            className="inline-block h-5 w-5"
                            style={{ verticalAlign: "middle" }}
                        />
                    </a>

                    <a
                        target="_blank"
                        aria-label="WhatsApp"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/channel/UCbjYROOdRtc_y1VEUeCiFBQ"
                        className={`icon-link`}
                    >
                        <FaYoutube
                            className="inline-block h-5 w-5"
                            style={{ verticalAlign: "middle" }}
                        />
                    </a>

                    <Link to="/contacto" className={`icon-link`} title="Contacto">
                        <FaEnvelope
                            className="inline-block h-4 w-4"
                            style={{ verticalAlign: "middle" }}
                        />
                    </Link>

                    {process.env.GATSBY_AUTH_ENABLED === "true" &&
                        (loading ? (
                            <span>
                                <AiOutlineLoading className="text-center w-4 h-4 animate-spin duration-300" />
                            </span>
                        ) : !isAuthenticated ? (
                            <button
                                // onClick={() => setShowLoginModal(true)}
                                onClick={() => {
                                    navigate(
                                        `/login${
                                            location?.search ? "?redirect=" + location.search : ""
                                        }`,
                                    );
                                }}
                                className="icon-link"
                                title="Iniciar sesión"
                            >
                                <FaKey
                                    className="w-4 h-4"
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                />
                            </button>
                        ) : (
                            <Link
                                to="/private"
                                title={`Autenticado como ${state?.user?.username}`}
                                className="flex items-center justify-items-center duration-300 icon-link"
                                activeClassName="!text-ccyan"
                            >
                                <BiFace className="w-5 h-5" />
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default PreHeader;
