import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "@styles/BackToTop.css";

const BackToTop = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", scrollListener);
        return () => window.removeEventListener("scroll", scrollListener);
    }, []);

    const scrollListener = () => {
        const offset = window.pageYOffset;
        if (offset > 50) {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    // Hacemos scroll arriba
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className="backtotop">
            <button
                aria-label="Volver arriba"
                className={`button mb-5 ${show ? "-mb-0 opacity-100" : "-mb-20 opacity-0"}`}
                onClick={() => scrollToTop()}
            >
                <FaArrowUp className="h-4 w-4" style={{ verticalAlign: "middle" }} />
            </button>
        </div>
    );
};
export default BackToTop;
